body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.pac-container {
  z-index: 10000 !important;
}

.tiptap:focus {
  outline: none !important;
  border: none !important;
}

h2 {
  font-size: "30px";
}

.react-datepicker {
  z-index: 1500; /* High enough to appear above everything */
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a0a3bd;
  border-radius: 3px;
}
